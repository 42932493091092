.modalBackgroundBase {
  height: 70%;
  width: 70%;
  top: 17%;
  left: 16%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  padding: 1.7%;
}

.modalContainerBase {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: linear-gradient(#8b5a11, #cca879);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 2%;
  text-align: center;
}

.modalBackgroundInfo {
  height: 21%;
  width: 20%;
  top: 0%;
  left: 18%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  /* padding: 1.7%; */
}

.modalContainerInfo {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: linear-gradient(#8b5a11, #cca879);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 5px;
  text-align: center;
}

.modalBackgroundScope{
  height: 50%;
  width: 35%;
  top: 28%;
  left: 32%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  /* padding: 1.7%; */
}

.modalContainerScope {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  /* background: linear-gradient(#8b5a11, #cca879); */
  /* border: solid 1rem; */
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */

}


/* 
img {
  display: block;
} */

h1 {
  font-size: 34px;
  color: #350e14;
  /* margin-top: 1%; */
}
h2 {
  font-size: 20px;
  margin-top: 3%;
}

p {
  font-size: 20px;
  margin-top: 3%;
}

.modalContainerBase .body .discover {
  display: flex;
  /* background-color: rgb(162, 0, 255); */
  justify-content: center;
  align-items: center;
  flex: 10%;
  margin-top: 4%;
}

button {
  border: none;
  position: relative;
  width: 100%;
  height: 100%;
}

.buttons {
  /* background-color: #8b5a11; */
  justify-content: space-around;
  margin: 0 20%;
  display: flex;
}

.navButton {
  width: 120px;
  height: 45px;
  border: none;
  text-align: center;
  position: relative;
  background-color: transparent;
  /* vertical-align: middle; */
  border-radius: 15px;
  font-size: 23px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.navButton:hover {
  background-color: #350e14;
  transition: all 0.7s ease-in-out;
}

.short-text {
  display: none;
}

.mobile-info {
  display: none;
}

/* ----------------------------------------------------------------------------- */

.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: chartreuse;
  text-align: right;
}

/* --TV--------------------------------------------------------------------------- */

.modalBackgroundTv {
  height: 80%;
  width: 80%;
  top: 11%;
  left: 10%;
  position: absolute;
  display: flex;
  z-index: 999;
  padding: 5%;
}

.modalContainerTv {
  border-radius: 12px;
  width: 100%;
  height: 100%;
  background-image: url("../images/tvFrame.png");
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1.8rem;
  justify-content: center;
  align-items: center;
}

.modalContainerTv .projectVideo {
  width: 100%;
  height: 100%;
  /* background-color: burlywood; */
}

.modalContainerTv .projectVideo video {
  width: 100%;
  height: 100%;
}

.march19{
  width: 100%;
  height:100%;
}

/* -AVATARS--------------------------------------------------------------------------------- */

.avatars {
  padding: 4% 10%;
}

/* ----EDUCATIONAL------------------------------------------------------------------------- */

.earth {
  height: 80%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: aqua; */
}

.earth img {
  width: 20%;
}
/* ----VOTE------------------------------------------------------------------------- */

.voteprocess {
  height: 80%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: aqua; */
}

.voteprocess img {
  width: 70%;
}

/* ----FUTUREDEVS------------------------------------------------------------------------- */

.futurdevimg {
  height: 80%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: aqua; */
}

.futurdevimg img {
  width: 95%;
  border-radius: 14px;
}

/* ----------------------------------------------------------------------------- */

.processimages {
  height: 100%;
  width: 60%;
  align-items: center;
  /* padding: 4% 3%; */
  transform: translate(33%, 0%);
  margin-top: 5%;

  /* background-color: chartreuse; */
} /* ----------------------------------------------------------------------------- */

.scope {
  height: 80%;
  width: 80%;
  align-items: center;
  position: absolute;

  /* padding: 4% 3%; */
  transform: translate(13%, -25%);
  /* margin-top: 5%; */
  /* background-color: chartreuse; */
}

/* ----------------------------------------------------------------------------- */
.mystery {
  /* height: 60%;
  width: 60%; */
  display: flex;
  transform: scale(0.7) translate(0%, -12%);
}

/* ----------------------------------------------------------------------------- */

.nftexample {
  width: 85%;
  height: 68%;
  transform: translate(8%, 0);
  margin-top: 1%;
}

/* ----------------------------------------------------------------------------- */

.modalBackgroundBook {
  height: 65%;
  width: 65%;
  top: 18%;
  left: 20%;
  position: absolute;
  display: flex;
  justify-content: center;
  /* background-color: white; */
  align-items: center;
  z-index: 4;
}

.modalContainerBook {
  width: 100%;
  height: 100%;
  /* background-color: gray; */
  display: flex;
  flex-direction: column;
  text-align: center;
}

.modalContainerBook input {
  display: none;
}
.modalContainerBook .book {
  display: flex;
  /* background-color: chocolate;   */
  width: 100%;
  height: 100%;
}

.modalContainerBook .flip-book {
  width: 50%;
  height: 100%;
  /* background-color: olivedrab; */
  position: relative;
  perspective: 1500px;

  /* background-color: orangered; */
}

.modalContainerBook .flip {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: left;
  transform-style: preserve-3d;
  transform: rotateY(0deg);
  transition: 0.5s;
  color: #000;
  /* background-color: olivedrab; */
}
.modalContainerBook .cover {
  width: 50%;
  height: 100%;
  /* background-color: orchid; */
  background-image: url("../images/book-left.png");
  background-size: contain;
  background-repeat: no-repeat;
  padding: 14% 14%;
  /* opacity: 0.4; */
}
.modalContainerBook .front {
  position: absolute;
  background-image: url("../images/book-right.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /* background-color: palevioletred; */
  box-sizing: border-box;
  padding: 0 13px;
  box-shadow: inset 20px 0 50px rgba(0, 0, 0, 0.5) 0 2px 5px rgba(0, 0, 0, 0.5);
}

.modalContainerBook .back {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("../images/book-left.png");
  background-size: contain;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  z-index: 99;
  transform: rotateY(180deg);
  /* padding: 10% 15%; */
  backface-visibility: hidden;
  /* background-color: #000; */
}

.modalContainerBook .back .nftid {
  padding: 5% 10%;
  /* background-color: antiquewhite; */
}

.modalContainerBook .back .curedstats {
  padding: 12% 17%;
}

.modalContainerBook .back .nftsamples {
  padding: 8%;
}

.modalContainerBook .curedback {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("../images/book-left.png");
  background-size: contain;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  z-index: 99;
  padding: 25% 10%;
  transform: rotateY(180deg);
  backface-visibility: hidden;
  /* background-color: #000; */
}

.modalContainerBook .next-btn {
  position: absolute;
  bottom: 9%;
  right: 8%;
  cursor: pointer;
  color: chartreuse;
}

.modalContainerBook .back-btn {
  position: absolute;
  bottom: 9%;
  right: 8%;
  cursor: pointer;
  color: chartreuse;
}

.modalContainerBook .samples {
  display: flex;
  flex-direction: column;
  width: 55%;
  /* background-color: chartreuse; */
  margin-left: 18%;
  margin-top: 5%;
}

.modalContainerBook h1 {
  font-size: 30px;
  margin-top: 9%;
}
.modalContainerBook h2 {
  font-size: 20px;
  margin-top: 8%;
}

.modalContainerBook p {
  font-size: 18px;
  margin-top: 10%;
}
.modalContainerBook #p1 {
  z-index: 3;
}
.modalContainerBook #p2 {
  z-index: 2;
}
.modalContainerBook #p3 {
  z-index: 1;
}
.modalContainerBook #c1:checked ~ .flip-book #p1 {
  transform: rotateY(-180deg);
  z-index: 1;
}
.modalContainerBook #c2:checked ~ .flip-book #p2 {
  transform: rotateY(-180deg);
  z-index: 2;
}
.modalContainerBook #c3:checked ~ .flip-book #p3 {
  transform: rotateY(-180deg);
  z-index: 3;
}

.bookButton {
  width: 50%;
  height: 75px;
  border: none;
  text-align: center;
  position: relative;
  background-color: transparent;
  /* vertical-align: middle; */
  border-radius: 15px;
  font-size: 23px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-top: 15%;
}

.bookButton:hover {
  background-color: #350e14;
  transition: all 0.7s ease-in-out;
}

/* ----------------------------------------------------------------------------- */

#cancelBtn {
  background-color: crimson;
}

.escargotFixe {
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../images/icons/escargotTw.png");
  overflow: hidden;
  position: absolute;
  top: 84.5%;
  left: 0;
  width: 89%;
  height: 6%;
  /* background-color: violet; */
  display: flex;
  border: none;
  /* border-radius: 15%; */
  /* transition: all 0.8s ease-in-out; */
}

.vautouranim {
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../images/icons/vautourOnce.gif");
  overflow: hidden;
  position: absolute;
  top: 12%;
  left: 1%;
  width: 20%;
  height: 75%;
  display: flex;
  border: none;
  /* border-radius: 15%; */

  /* background-color: aqua; */
}

.vautourFixe {
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../images/icons/vautour.png");
  overflow: hidden;
  position: absolute;
  /* background-color: burlywood; */
  top: 12%;
  left: 1%;
  width: 20%;
  height: 75%;
  display: flex;
  border: none;
  /* border-radius: 15%; */

}

.escargotMove {
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../images/icons/escargotTwOnce.gif");
  overflow: hidden;
  position: absolute;
  top: 84.5%;
  left: 0;
  width: 89%;
  /* background-color: violet; */
  text-align: center;
  font-style: italic;
  font-size: small;

  height: 6%;
  display: flex;
  border: none;
  /* border-radius: 15%; */
  /* transition: all 0.8s ease-in-out; */
}

.escargotDcFixe {
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../images/icons/escargotDc.png");
  overflow: hidden;
  position: absolute;
  /* background-color: burlywood; */
  top: 92%;
  left: 2%;
  width: 89%;
  height: 6%;
  display: flex;
  border: none;
  /* border-radius: 15%; */
  /* transition: all 0.8s ease-in-out; */
}
.escargotDcMove {
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../images/icons/escargotDcOnce.gif");
  overflow: hidden;
  position: absolute;
  /* background-color: violet; */
  top: 92%;
  left: 0;
  width: 89%;
  height: 6%;
  display: flex;
  border: none;
  text-align: center;
  font-style: italic;
  font-size: small;
  /* border-radius: 15%; */
  /* transition: all 0.8s ease-in-out; */
}

a.button {
  text-decoration: none;
  color: initial;
  width: 100%;
}

/* --------------------@MEDIA-------------------------------------
------------------------------------------------------------------- */

@media only screen and (min-width: 650px) and (max-width: 1500px) {
  .modalBackgroundInfo {
    height: 35%;
    width: 30%;
    top: 0%;
    left: 10%;
    /* padding: 1.7%; */
  }
  .modalBackgroundInfo h1 {
    font-size: 17px;
    /* padding: 1.7%; */
  }
  .modalBackgroundInfo p {
    font-size: 15px;
    /* padding: 1.7%; */
  }

  .modalBackgroundBase {
    height: 100%;
    width: 100%;
    top: 0%;
    left: 0%;
    padding: 1%;
  }

  .modalContainerBase {
    padding: 1%;
    height: 90%;
  }

  .modalContainerBase h1 {
    font-size: 25px;
  }
  .modalContainerBase h2 {
    font-size: 14px;
    margin-top: 2%;
  }

  .modalContainerBase p {
    font-size: 13px;
    margin-top: 2%;
  }

  .avatars {
    padding: 2% 11%;
  }
  
  .nftexample {
    width: 80%;
    transform: translate(13%, -10%);
    margin-top: 3%;
  }

  .futurdevimg {
    margin-top: 2%;
  }


  .modalBackgroundBook {
    height: 100%;
    width: 90%;
    top: 2%;
    left: 5%;
    position: absolute;
    display: flex;
    justify-content: center;
    /* background-color: white; */
    align-items: center;
    z-index: 4;
  }

  .modalContainerBook .next-btn {
    position: absolute;
    bottom: 19%;
    right: 7%;
    cursor: pointer;
    color: chartreuse;
  }

  .modalContainerBook .back-btn {
    position: absolute;
    bottom: 19%;
    right: 7%;
    cursor: pointer;
    color: chartreuse;
  }

  .modalContainerBook h1 {
    font-size: 25px;
    margin-top: 5%;
  }
  .modalContainerBook h2 {
    font-size: 17px;
    margin-top: 10%;
  }

  .modalContainerBook p {
    font-size: 15px;
    margin-top: 10%;
  }

  /* .mystery {
    display: none;
  } */


  /* .short-text {
    display: inline-block;
  }
.full-text {
    display: none;
  } */
}

@media only screen and (max-width: 649px) {
  .modalBackgroundInfo {
    height: 40%;
    top: 0%;
    width: 25%;
    left:10%;
    /* padding: 0%; */
  }
  .modalBackgroundInfo h1 {
    font-size: 12px;
    /* padding: 1.7%; */
  }
  .modalBackgroundInfo p {
    font-size: 12px;
    /* padding: 1.7%; */
  }
  .titleCloseBtn button {
    font-size: 14px;
  }

  .modalBackgroundBase {
    height: 160%;
    width: 100%;
    top: 0%;
    left: 0%;
    padding: 1%;
  }

  .modalContainerBase {
    padding: 1%;
  }

  .modalContainerBase h1 {
    font-size: 25px;
  }
  .modalContainerBase h2 {
    font-size: 14px;
    margin-top: 2%;
  }

  .modalContainerBase p {
    font-size: 13px;
    margin-top: 2%;
  }

  .avatars {
    padding: 12% 0%;
  }

  .modalBackgroundTv {
    height: 100%;
    width: 100%;
    top: 0%;
    left: 0%;
    position: relative;
    display: flex;
    z-index: 999;
    padding: 5%;
  }
  
  .nftexample {
    width: 95%;
    transform: translate(2%, -10%);
    margin-top: 10%;
  }

  .voteprocess {
    height: 100%;
    width: 100%;
  }

  .voteprocess img {
    width: 90%;
  }

  .modalBackgroundScope{
    height: 90%;
    width: 90%;
    top:0;
    left:0;
  }
  .scope {
    height: 80%;
    width: 80%;
    align-items: center;
    position: relative;
  
    /* padding: 4% 3%; */
    transform: translate(20%, 0%);
    /* margin-top: 5%; */
    /* background-color: chartreuse; */
  }

  .modalBackgroundBook {
    height: 100%;
    width: 100%;
    top: 2%;
    /* left: 5%; */
    transform: translate(-20%, 0%);
    position: absolute;
    display: flex;
    justify-content: center;
    /* background-color: white; */
    align-items: center;
    z-index: 4;
  }

  .modalContainerBook .next-btn {
    position: absolute;
    bottom: 6%;
    right: 7%;
    font-size: small;
    cursor: pointer;
    color: chartreuse;
  }

  .modalContainerBook .back-btn {
    position: absolute;
    bottom: 6%;
    right: 7%;

    font-size: small;
    cursor: pointer;
    color: chartreuse;
  }

  .modalContainerBook h1 {
    font-size: 16px;
    margin-top: 5%;
  }
  .modalContainerBook h2 {
    font-size: 12px;
    margin-top: 10%;
  }

  .modalContainerBook p {
    font-size: 11px;
    margin-top: 10%;
  }

  .short-text {
    display: inline-block;
  }
  .full-text {
    display: none;
  }

  .mobile-info {
    display: inline-block;
  }

  .web-info {
    display: none;
  }

  .bookButton {
    width: 50%;
    height: 25px;
    border: none;
    text-align: center;
    position: relative;
    background-color: transparent;
    /* vertical-align: middle; */
    border-radius: 15px;
    font-size: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin-top: 5%;
  }

  .bookButton:hover {
    background-color: #350e14;
    transition: all 0.7s ease-in-out;
  }

  .mystery {



    /* padding: 4% 3%; */
    transform: scale(0.9) translate(0%, 20%);
    /* margin-top: 5%; */
    /* background-color: chartreuse; */
  }
}

@media only screen and (max-width: 385px) {
  
  .modalContainerBase h1 {
    font-size: 16px;
  }
  .modalContainerBase h2 {
    font-size: 10px;
    margin-top: 2%;
  }

  .modalContainerBase p {
    font-size: 9px;
    margin-top: 2%;
  }

  
  .modalContainerBook h1 {
    font-size: 10px;
    margin-top: 5%;
  }
  .modalContainerBook h2 {
    font-size: 9px;
    margin-top: 10%;
  }

  .modalContainerBook p {
    font-size: 8px;
    margin-top: 10%;
  }
}
