
/* GENERAL */

img {
  width: 100%;
}

/* BACKGROUND */

.background {
  position: absolute;
}

.tree {
  position: absolute;
  z-index: 2;
}

.pays {
  position: fixed;
  z-index: 1;
}

/* NAVBAR */

.navbar {
  width: 100%;
  height: 10%;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  z-index: 97;
  padding: 0 2%;
  background-color: #664117c0;
}

.navbar li {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  color: #F7F6CF;
  font-size: 25px;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
}

.navbar li:hover {
  background-color: transparent;
  opacity: 0.4;
  transform: scale(0.9);
}

.navbar .logo {
  object-fit: contain;
  overflow: hidden;
  height: 98%;
  margin-top: 1%;
}

/* INFO */

.info {
  background-color: rgb(163, 112, 0);
  box-shadow: inset -25px -15px 40px rgba(0, 0, 0, 0.3);
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 220, 0.3) 0%,
    transparent 100%
  );
  color: chartreuse;
  position: absolute;
  width: 7%;
  height: 10%;
  left: 25%;
  top: 0%;
  font-size: 40px;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  opacity: 0.9;
}

.info:hover {
  background-color: #350e14;
  transition: all 0.7s ease-in-out;
}

/* ROADMAP ET FEUILLE */

.roadmapcontainer {
  height: 100%;
  width: 92%;
  position: absolute;
  bottom: 1%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.feuillegauchecontainer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 140%;
  flex-direction: column;
  z-index: 99;
}

.feuillegauchediv {
  height: 1600px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../images/feuille.png");
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 7px;
}

.feuilledroitecontainer {
  display: flex;
  justify-content: right;
  width: 100%;
  flex-direction: column;
  background-color: #cca879;
}

.feuilledroitediv {
  height: 300px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../images/feuille.png");
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 7px;
}

/* -TV--------------------------------------------------------------------------- */
.openTvBtn {
  background-image: url("../images/TvNoise_1.gif");
  overflow: hidden;
  z-index: 4;
  position: absolute;
  top: 34.3%;
  left: 58.6%;
  width: 7.7%;
  height: 6.7%;
  display: flex;
  border: none;
  transition: all 0.8s ease-in-out;
  cursor: pointer;
}

/* --BUBBLES--------------------------------------------------------------------------- */
.openModalBtn {
  background-color: transparent;
  overflow: hidden;
  position: absolute;
  height: 8.9%;
  width: 13.1%;
  left: 42.9%;
  top: 80.2%;
  display: flex;
  border: none;
  background-image: url("../images/icons/WEBANIM_BUBBLES.gif");
  background-repeat: no-repeat;
  background-size: contain;
}

/* ---FUTUR DEV-------------------------------------------------------------------------- */
.openMetaverseBtn {
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  /* background-color: violet;  */
  overflow: hidden;
  position: absolute;
  top: 45%;
  left: 53%;
  width: 16.5%;
  height: 14%;
  display: flex;
  border: none;
  cursor: pointer;
}

.openMetaverseBtn:hover {
  background-image: url("../images/icons/WEBANIM_CARTES2.gif");
  top: 25.3%;
  left: 52.8%;
  width: 16.1%;
  height: 34%;
}

/* ----FLACON------------------------------------------------------------------------- */
.openFlaconsBtn {
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../images/icons/flacons.png");
  position: absolute;
  overflow: hidden;
  top: 61.1%;
  left: 33.4%;
  width: 18%;
  height: 13.4%;
  display: flex;
  border: none;
  z-index: 0;
}

.openFlaconsBtn:hover {
  background-image: url("../images/icons/WEBANIM_FLACONS.gif");
  top: 46.7%;
  left: 33.4%;
  width: 18%;
  height: 28%;
  /* background-color: rebeccapurple; */
}

/* ---NFT-------------------------------------------------------------------------- */

.openNftBtn {
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  overflow: hidden;
  position: absolute;
  top: 69.1%;
  left: 56%;
  width: 9%;
  height: 12.5%;
  /* background-color: green; */
  display: flex;
  border: none;
  border-radius: 5%;
  cursor: pointer;
}
.openNftBtn:hover {
  background-image: url("../images/icons/WEBANIM_NFT.gif");
  /* background-color: violet; */
  top: 62.1%;
  left: 39.1%;
  width: 28.1%;
  height: 28.1%;
  z-index: 99;
}

/* ---PROCESS-------------------------------------------------------------------------- */
.openPencilBtn {
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../images/icons/pencil.png");
  /* background-color: violet; */
  overflow: hidden;
  position: absolute;
  top: 74.2%;
  left: 44.1%;
  width: 7.8%;
  height: 5%;
  display: flex;
  border: none;
  z-index: 0;
  cursor: pointer;
}
.openPencilBtn:hover {
  background-image: url("../images/icons/WEBANIM_PINCEAU.gif");
  /* background-color: violet; */
  top: 61.6%;
  left: 41.3%;
  width: 17%;
  height: 20%;
  z-index: 99;
}
/* ---BOOK-------------------------------------------------------------------------- */
.openBookBtn {
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../images/icons/WEBANIM_BOOK_BAIT.gif");
  /* background-color: floralwhite; */
  overflow: hidden;
  position: absolute;
  top: 72.3%;
  left: 35%;
  width: 9%;
  height: 13%;
  display: flex;
  border: none;
  cursor: pointer;
}
.openBookBtn:hover {
  background-image: url("../images/icons/WEBANIM_BOOK_ANIM.gif");
  transition: all 0.2s ease-in-out;
  /* background-color: violet; */
  background-size: cover;
  height: 60%;
  width: 60%;
  top: 22%;
  left: 20%;
}

/* ----EDUCATIONAL------------------------------------------------------------------------- */
.openTableauBtn {
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../images/icons/WEBANIM_TABLEAU_2.gif");
  /* background-color: bisque; */
  overflow: hidden;
  position: absolute;
  top: 38.4%;
  left: 32%;
  width: 11.5%;
  height: 10%;
  display: flex;
  border: none;
  /* transition: all 0.8s ease-in-out; */
  cursor: pointer;
}
/* ------VOTE!!!----------------------------------------------------------------------- */
.openVoteBtn {
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../images/icons/WEBANIM_VOTE.gif");
  /* background-color: violet; */
  overflow: hidden;
  position: absolute;
  top: 30%;
  left: 58%;
  width: 7.20%;
  height: 10.9%;
  display: flex;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.8) 0px 5px 15px;
  /* transition: all 0.8s ease-in-out; */
  cursor: pointer;
}
.openVoteBtn:hover {
  transition: all 3s ease-in-out;
  /* background-color: violet; */
}

.voteprocess img {
  width: 70%;
}

/* -------ESCARGOT---------------------------------------------------------------------- */
/* .openEscargotTwBtn {
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../images/icons/boutonescargot.png");
  overflow: hidden;
  position: absolute;
  top: 84.2%;
  left: 90.5%;
  width: 4%;
  height: 7%;
  display: flex;
  border: none;
  transition: all 0.8s ease-in-out;
}
.openEscargotTwBtn:hover {
  background-image: url("../images/icons/boutonescargot.png");
  cursor: pointer;
}

.openEscargotDcBtn {
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../images/icons/boutonescargot.png");
  overflow: hidden;
  position: absolute;
  top: 91.7%;
  left: 92.2%;
  width: 4%;
  height: 7%;
  display: flex;
  border: none;
  transition: all 0.8s ease-in-out;
  cursor: pointer;
}
.openEscargotDcBtn:hover {
  background-image: url("../images/icons/boutonescargot.png");
} */

.openVautourBtn {
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  /* background-image: url("../images/icons/vautour.png"); */
  overflow: hidden;
  position: absolute;
  top: 1%;
  left: 1%;
  width: 20%;
  height: 75%;
  display: flex;
  border: none;
  /* transition: all 0.8s ease-in-out; */
  cursor: pointer;
  z-index: 1;
}
/* LAMPE */
 
.openLampeBtn {
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../images/icons/WEBANIM_LAMPE_2.png");
  overflow: hidden;
  position: absolute;
  /* background-color: #cca879; */
  top: 25%;
  left: 27.9%;
  width: 18%;
  height: 31%;
  display: flex;
  border: none;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
}

.openLampeBtn:hover {
  background-image: url("../images/icons/WEBANIM_LAMPE_3.gif");
}

/* SCOPE */
 
.openScopeBtn {
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../images/icons/WEBANIM_TELESCOPE.png");
  overflow: hidden;
  position: absolute;
  /* background-color: #cca879; */
  top: 35%;
  left: 45%;
  width: 6%;
  height: 20%;
  display: flex;
  border: none;
  transition: all 0.8s ease-in-out;
  cursor: pointer;
}


/* MARKET  */

/* PELUCHES GIFTS */
 
.openPeluchesBtn {
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../images/icons/WEBANIM_PELUCHEv1.gif");
  overflow: hidden;
  position: absolute;
  top: 40.8%;
  left: 30.8%;
  width: 36.3%;
  height: 40%;
  display: flex;
  border: none;
  transition: all 0.8s ease-in-out;
  cursor: pointer;
}
/* 
.openPeluchesBtn:hover {
  background-image: url("../images/icons/peluches.gif");
} */

/* MARKET  */

.openMarketBtn {
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../images/icons/WEBANIM_CLOSED_FIX.png");
  overflow: hidden;
  position: absolute;
  top: 27.3%;
  left: 42.7%;
  width: 14.5%;
  height: 10%;
  display: flex;
  border: none;
  transition: all 0.1s ease-in-out;
}
.openMarketBtn:hover {
  background-image: url("../images/icons/WEBANIM_CLOSED.gif");
  /* background-color: violet; */
}


/* MAIL BOX */

.openMailBtn {
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  overflow: hidden;
  position: absolute;
  top: 52.7%;
  left: 20%;
  width: 14.5%;
  height: 7.3%;
  display: flex;
  border: none;
  cursor: pointer;
  /* background-color: violet; */
}
.openMailBtn:hover {
  background-image: url("../images/icons/WEBANIM_MAIL.gif");
  /* background-color: violet; */
}
.openMailBtn a {
  text-decoration: none;
  font-size: 0;
  color: initial;
  width: 100%;
  height: 100%;
}

/* PANNEAUX */
.openMintBtn {
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  overflow: hidden;
  position: absolute;
  background-image: url("../images/icons/WEBANIM_MINT_FIX.png");
  top: 46%;
  left: 65%;
  width: 20%;
  height: 20%;
  display: flex;
  border: none;
  /* background-color: violet; */
}
.openMintBtn:hover {
  background-image: url("../images/icons/WEBANIM_MINT.gif");
  /* background-color: violet; */
}
.openMintBtn a {
  text-decoration: none;
  color: initial;
  width: 100%;
  height: 100%;
  font-size: 0;
}
.openGalleryBtn {
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  overflow: hidden;
  position: absolute;
  background-image: url("../images/icons/WEBANIM_GALLERY_FIX.png");
  top: 30%;
  left: 69%;
  width: 25%;
  height: 25%;
  display: flex;
  border: none;
  /* background-color: violet; */
}
.openGalleryBtn:hover {
  background-image: url("../images/icons/WEBANIM_GALLERY.gif");
  /* background-color: violet; */
}
.openGalleryBtn a {
  text-decoration: none;
  color: initial;
  width: 100%;
  height: 100%;
  font-size: 0;
}

/* @MEDIA SCREEN */

@media only screen and (min-width: 650px) and (max-width: 1500px) {
  .feuillegauchecontainer {
    /* display:none; */
    margin-top: 280%;
  }
}

@media only screen and (max-width: 649px) {
  .navbar {
    height: 8%;
  }
  .navbar .logo {
    height: 80%;
  }

  .navbar li {
    /* font-size: 12px; */
    display: none;
  }

  .info {
    width: 13%;
    height: 20%;
    left: 15%;
    top: 0%;
    font-size: 35px;
  }

  /* .openPeluchesBtn:hover:active {
    background-image: url("../images/icons/peluches.gif");
  } */

  .feuillegauchecontainer {
    display: none;
  }
}
