/* @import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;500&family=VT323&display=swap');
*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}

html{
    font-family: 'Roboto Mono', monospace;
} */

/* @import url("https://fonts.googleapis.com/css2?family=VT323&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-family: "VT323", monospace;
} */

@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200;300;400;600;700&display=swap');
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    font-family: 'Titillium Web', sans-serif;
  }